<script>
import DataService from "../../services/DataService";
  export default {
      name: 'listing-status',
      props: {
        dataid: {
          type: Number,
          required: true,
        },
        dataselected: {
          type: String,
          required: true,
        },
        dataController: {
          type: String,
          required: true,
        },
        dataAction: {
          type: String,
          required: true,
        },
        isfor: {
          type: String,
          required: true,
        },
        module: {
          type: String,
          required: true,
        },
        subModule: {
          type: String,
          required: true,
        },
        callbackfunction: {
          type: Function,
        },
        statuslist: {
          type: Array,
          required: true,
        },
        isselectedvaluestring: {
          type: Boolean,
        },
        parentid: {
          type: Number,
        },
        haschild: {
          type: Boolean,
        },
        hasglobal: {
          type: Boolean,
          required: false,
        },
      },
      data: function () {
        return {
          StatusId: this.dataselected,
          StatusCode: "",
          PrevStatus: this.dataselected,
          IsReponsive: false,
          StatusList: this.statuslist,
          item: this.item,
          StatusColor: '#ffffff',
          StatusBgColor: '#525f65',
          isShowLoader: true
        }
      },
      created: function () {
        var vueCom = this;
        if (vueCom.hasglobal == true || vueCom.hasglobal == "true") {
          vueCom.StatusList = this.GetStatusList();
        }
        $.each(vueCom.statuslist, function (filedIndex, fieldItem) {
          if (vueCom.statuslist[filedIndex].STATUS == "Closed") {
            vueCom.StatusCode = vueCom.statuslist[filedIndex].STATUS_ID;
          }
        });
        try {
          this.isShowLoader = !(this.statuslist.length > 0);
        }
        catch (ex) {
          this.isShowLoader = false;
        }
        var ths = this;
        if (this.isselectedvaluestring == true) {
          var itm = this.StatusList.filter(function (entry) { return entry.STATUS == ths.dataselected; });
          if (itm.length > 0) {
            this.StatusId = itm[0].STATUS_ID;
            this.PrevStatus = itm[0].STATUS_ID;
            this.GetColor();
          } else {
            this.StatusId = "";
            this.PrevStatus = "";
          }
        } else {
          this.StatusId = this.dataselected;
          this.PrevStatus = this.dataselected
          this.GetColor();
        }
        this.GetColor();
      },
      mounted: function () {
        var vm = this;
        $("body").on("change", ".ddlchangestatus", function (event) {
          event.stopImmediatePropagation();
          vm.ChangeStatus($(this).attr("data-id"), event, event.target.value);
        });
      },
      watch: {
        dataselected: function (value) {
          if (this.isselectedvaluestring == true) {
            var itm = this.StatusList.filter(function (entry) { return entry.STATUS == value; });
            if (itm.length > 0) {
              this.StatusId = itm[0].STATUS_ID;
              this.PrevStatus = itm[0].STATUS_ID;
              this.GetColor();
            } else {
              this.StatusId = "";
              this.PrevStatus = "";
            }
          } else {
              this.StatusId = value;
              this.PrevStatus = value;
              this.GetColor();
          }
          this.GetColor();
        },
        statuslist: function (value) {
            this.isShowLoader = false;
            this.StatusList = value;
            var ths = this;
            if (ths.isselectedvaluestring == true) {
                var itm = ths.StatusList.filter(function (entry) { return entry.STATUS == ths.dataselected; });
                if (itm.length > 0) {
                    ths.StatusId = itm[0].STATUS_ID;
                    ths.PrevStatus = itm[0].STATUS_ID;
                    ths.GetColor();
                } else {
                    ths.StatusId = "";
                    ths.PrevStatus = "";
                    this.GetColor();
                }
            } else {
                this.StatusId = value;
                ths.PrevStatus = value;
                this.GetColor();
            }
        }
      },
      methods: {
          GetColor: function () {
              var vm = this;
              //vm.$forceUpdate();
              if (vm.StatusList != null && vm.StatusList.length > 0) {
                  var returnedData = $.grep(vm.StatusList, function (element, index) {
                      return element.STATUS_ID == vm.StatusId;
                  });
                  if (returnedData != null && returnedData.length > 0) {
                      vm.StatusColor = returnedData[0].STATUS_COLOR;
                      vm.StatusBgColor = returnedData[0].STATUS_BG_COLOR;

                      if (vm.StatusColor == null || vm.StatusColor == '') {
                          vm.StatusColor = '#ffffff';
                          vm.StatusBgColor = '#525f65';
                      }
                      else {
                          vm.StatusId = returnedData[0].STATUS_ID;
                      }
                  }
                  //else {
                  //    var itm = vm.StatusList.filter(function (entry) { return entry.STATUS == vm.dataselected; });
                  //    ths.StatusId = itm[0].STATUS_ID;
                  //}
              }
          },
          GetStatusList: function () {
              var list = [];
              var obj = {
                  STATUS_ID: 1001,
                  STATUS: this.$t("Active"),
                  STATUS_COLOR: '#ffffff',
                  STATUS_BG_COLOR: '#28a745',
              }
              list.push(obj);
              obj = {
                  STATUS_ID: 1002,
                  STATUS: this.$t("Inactive"),
                  STATUS_COLOR: '#ffffff',
                  STATUS_BG_COLOR: '#d72435'
              }
              list.push(obj);
              return list;
          },
          ChangeStatus: function (id, event, statusId) {
              var StatusName = event.target.selectedOptions[0].text;
              var vm = this;
              if (1 == 1) {
                  var urlval = "";
                  if (StatusName == "Closed" && (vm.dataController + '/' + vm.isfor) == "Crm/CRM_LEADS") {
                      urlval = '/' + vm.dataController + '/CloseLeads';
                  }
                  else {
                      urlval = '/' + vm.dataController + '/' + vm.dataAction;
                  }
                  // if (vm.GetPrivilege(urlval, true, false)) {

                      if (vm.PrevStatus != $(event.target).attr("oldstatus")) {
                          vm.PrevStatus = $(event.target).attr("oldstatus")
                      }

                      var msg = this.$t("ConfirmUpdateStatusRecord");
                      var isFor = vm.isfor;
                      if (vm.isfor == "TASKLISTING") {
                        msg = vm.$t("ConfirmUpdateTaskStatus");
                          if (vm.haschild == true) {
                              msg = this.$t("ConfirmUpdateTaskStatus");
                          }
                          else if (vm.haschild == false && Number(vm.parentid) > 0) {
                              msg = this.$t("ConfirmUpdateChildTaskStatus");
                          }
                          else {
                              msg = this.$t("ConfirmUpdateStatusRecord");
                          }
                      }
                      var isStatusChange = false;

                      var inprogressStatusId = 0;
                      var pendingStatusId = 0;

                      $.each(vm.statuslist, function (filedIndex, fieldItem) {
                          if (fieldItem.STATUS_CODE == "REFPEN") {
                              pendingStatusId = fieldItem.STATUS_ID;
                          }
                          else if (fieldItem.STATUS_CODE == "PNPGRS") {
                              InprogressStatusId = fieldItem.STATUS_ID;
                          }
                      });

                      if (Number(vm.parentid) > 0 && vm.PrevStatus == "1002" && vm.StatusId == "1001") {
                          isStatusChange = true;
                      }
                      else if (Number(vm.parentid) > 0 && vm.PrevStatus != "1002" && vm.StatusId == 1001) {
                          isStatusChange = true;
                      }
                      else if (Number(vm.parentid) > 0 && vm.StatusId == Number(pendingStatusId)) {
                          isStatusChange = true;
                      }
                      else if (Number(vm.parentid) > 0 && vm.StatusId == Number(InprogressStatusId)) {
                          isStatusChange = true;
                      }
                      if (isStatusChange) {
                          //msg = Globalize.localize("ConfirmUpdateChildTaskStatus");
                          isFor = "TASKCHILDLISTING"
                      }
                      if (vm.isfor == "TASKLISTING") {
                          if (isStatusChange) {
                              msg = this.$t("ConfirmUpdateChildTaskStatus");
                          }
                          else {
                              msg = this.$t("ConfirmUpdateTaskStatus");
                          }
                      }
                      if (/*GetPrivilege('/Project/UpdateProjectStatus', true, false)*/ 1 == 1) {
                        vm.confirmR(msg,true, this.$t('UpdateCommonStatus'),function(result) {   
                          if (statusId) {
                            vm.StatusId = statusId;
                          }
                          var VarObject = {
                            // ID: id,
                            // StatusId: vm.StatusId,
                            // IsFor: isFor
                            ID: id,
                            StatusId: vm.StatusId,
                            IsFor: isFor,
                            Module: vm.module,
                            Submodule: vm.subModule,
                          }
                          var objectPostString = JSON.stringify(VarObject);
                          DataService.CommonUpdateStatus(objectPostString).then(function (response) {
                            if (response.data != null) {
                              if (response.data.status === 'Success') {
                                vm.ShowAlert(vm.$t("RecordUpdatedSuccessfully"), "success",vm.$t('Alert'));
                                vm.PrevStatus = vm.StatusId;
                                if (typeof vm.callbackfunction != 'undefined' && vm.callbackfunction != null) {
                                  vm.callbackfunction();
                                }
                              } else {
                                vm.ShowAlert(vm.$t("UnknownErrorOccur"), "failure", true, vm.$t('Alert'));
                              }
                            } else {
                              vm.ShowAlert(vm.$t("UnknownErrorOccur"), "failure", true, vm.$t('Alert'));
                            }
                          }, function (response) {
                            console.log(response, "check response");
                          });   
                        },
                        function(result) {
                          vm.StatusId = vm.PrevStatus;
                        }
                        )
                      } else {
                        console.log("Set 2");
                        vm.StatusId = vm.PrevStatus;
                      }
                  // }
                  // else {
                  //     vm.StatusId = vm.PrevStatus;
                  // }
              }
              else {
                console.log("Set 3");
                  vm.StatusId = vm.PrevStatus;
              }
              //}
          }
      }
  };
</script>
<template>
  <div class="cstm-drop-btn">
      <button v-if="isShowLoader" class="btn btn-dark w-100" type="button" disabled style="padding: 2px 10px 2px 2px;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</button>
      <select id="crmlistingStatus_span" v-bind:disabled="StatusCode==StatusId" v-if="!isShowLoader" class="w-100" v-bind:oldstatus="PrevStatus" v-model="StatusId" v-on:change="ChangeStatus(dataid,$event)" checkPrivilege="yes" v-bind:data-id="IsReponsive? dataid : null" v-bind:style="{backgroundColor: StatusBgColor ,color: StatusColor}" v-bind:data-status="IsReponsive ? StatusId : null" v-bind:class="{'ddlchangestatus': IsReponsive}">
          <option v-for="(s,index) in StatusList" :key="index" v-bind:value="s.STATUS_ID">
              {{s.STATUS}}
          </option>
      </select>
  </div>
</template>
