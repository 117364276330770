<template>
    <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <em class="fa fa-home"></em><RouterLink to="/Dashboard/Home">Home</RouterLink>
          </li>
          <template v-for="(item,itemIndex) in breadcrumbList">
            <li class="breadcrumb-item" :key="itemIndex" v-if="item.link"><RouterLink :to="item.link" >{{item.name}}</RouterLink></li>
            <li class="breadcrumb-item active" v-if="!item.link" :key="itemIndex">{{item.name}}</li>
          </template>
        </ol>
</template>
<script>
    export default{
       name: "breadcrumb",
       data: function(){
            return{
                breadcrumbList: []
            }
       },
       mounted() {
            this.updateBreadcrumb();
       },
       watch: {
           '$route':function(){
                this.updateBreadcrumb();
           }
       },
       methods: {
            updateBreadcrumb: function(){
                if(Object.keys(this.$route.meta).length > 0 && Object.keys(this.$route.meta).indexOf("breadcrumb") > -1){
                    this.breadcrumbList = this.$route.meta.breadcrumb;
                }else{
                    this.breadcrumbList = [];
                }
            }
       }
    }
</script>